import React, { Fragment, useEffect, useMemo, useState } from "react";
// pages for this product
import HomePage from "../../../views/HomePage/HomePage";
import CategoryPage from "../../../views/CategoryPage/CategoryPage";
import ProductPage from "../../../views/ProductPage/ProductPage";
import InformationPage from "../../../views/InformationPage/InformationPage";
import FormPage from "../../../views/FormPage/FormPage";
import PromotionPage from "../../../views/PromotionPage/PromotionPage";
import CartPage from "../../../views/CartPage/CartPage";
import DeliveryPage from "../../../views/DeliveryPage/DeliveryPage";
import CheckoutPage from "../../../views/CheckoutPage/CheckoutPage";
import OrderPage from "../../../views/OrderPage/OrderPage";
import OnboardingPage from "../../../views/OnboardingPage/OnboardingPage";
import WelcomePage from "../../../views/WelcomePage/WelcomePage";
import SearchPage from "../../../views/SearchPage/SearchPage";
import PayLinkPage from "../../../views/PaymentPage/PayLinkPage";
import NgeniusPage from "../../../views/PaymentPage/NgeniusPage";
import XpressPayPage from "../../../views/PaymentPage/XpressPayPage";
import SkipCashPage from "../../../views/PaymentPage/SkipCashPage";
import PayTabsPage from "../../../views/PaymentPage/PayTabsPage";
import NoonPage from "../../../views/PaymentPage/Noonpage";
import { createTheme } from "@material-ui/core/styles";
import { Button, Snackbar } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  customDomainLicense,
  getMenuLanguages,
  refreshMenuInfo,
  saveLicense,
  setMenuClosed,
  setMenuOpen,
} from "../../../store/actions/menu";
import { is_open } from "../../../constants/utils";
import { useDispatch, useSelector } from "react-redux";
import RTL from "../RTL/RTL";
import { DateTime } from "luxon";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";
import { APP_URL } from "../../../constants/Api";
import {
  enableWishlist,
  getIikoActiveOrders,
  resetIikoActiveOrders,
  saveOrderLocation,
} from "../../../store/actions/cart";
import Hashids from "hashids/esm";
import AlFuttaimCheckoutPage from "../../../views/AlFuttaimCheckoutPage/AlFuttaimCheckoutPage";
import { Alert } from "@material-ui/lab";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Slide from "@material-ui/core/Slide";
import StripePage from "../../../views/PaymentPage/StripePage";

const cartSnackbarTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

export default function Switches() {
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const license = window._EMENU_LICENSE_ || menuInfo.license || "pending";
  const reactLocation = useLocation();
  const theme = createTheme({});
  const dispatch = useDispatch();
  const {
    opening_hours,
    closing_rules,
    timezone,
    qr_app_version,
    enable_table_orders_to_wishlist,
    al_futtaim_enabled,
  } = menuInfo?.menuData;

  const {
    orderType: cOrderType,
    orderLocation: cOrderLocation,
    orderLocationType: cOrderLocationType,
    afActiveOrder,
    customDomain,
    ui_strings,
    products,
  } = cartInfo;

  const hashids = useMemo(() => {
    return new Hashids("qr-emenu-hash");
  }, []);

  // eslint-disable-next-line no-unused-vars
  const [_, setCartCount] = useState(0);

  useEffect(() => {
    setCartCount((prev) => {
      if (prev < products?.length || 0) {
        setOpenCartSuccess(true);
      }
      return products?.length || 0;
    });
  }, [products]);

  const [activeOrderNotification, setActiveOrderNotification] = useState(
    !!afActiveOrder
  );
  const pathname = window.location.pathname.split("/");
  const [orderLocation, setOrderLocation] = useState(cOrderLocation);
  const [orderLocationType, setOrderLocationType] = useState(
    cOrderLocationType
  );

  const [openCartSuccess, setOpenCartSuccess] = React.useState(false);

  const handleCartSuccess = (event) => {
    setOpenCartSuccess(false);
  };

  const handleActiveOrder = (event) => {
    setActiveOrderNotification(false);
  };

  const handleIsOpen = () => {
    if (is_open(opening_hours, closing_rules, timezone)) {
      dispatch(setMenuOpen());
    } else {
      dispatch(setMenuClosed());
    }
  };

  const [showReload, setShowReload] = useState(false);
  const reloadPage = () => {
    setShowReload(false);
    window.location.reload(true);
  };
  const currentVersion = process.env.REACT_APP_VERSION;
  const serverVersion = qr_app_version || null;

  useEffect(() => {
    // Refresh data automatically every hour
    if (menuInfo?.persistedAt && menuInfo?.selected_language && license) {
      const savedDate = menuInfo.persistedAt;
      const currentDate = DateTime.now();
      const diffHours = currentDate.diff(savedDate, "minutes");
      // console.log(diffHours.toObject().minutes);
      if (diffHours.toObject().minutes > 1) {
        // Set Menu is Open or Closed - check every minute
        if (menuInfo?.menuData) {
          handleIsOpen();
        }
        dispatch(refreshMenuInfo(license, menuInfo.selected_language));
      }

      // reload when new app version is available
      // As after fetching new updates, it will once again reload to update - poor UX.
      if (currentVersion && serverVersion) {
        const comparison = serverVersion.localeCompare(
          currentVersion,
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );

        if (comparison > 0) {
          // New version available
          setShowReload(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactLocation, license, currentVersion, serverVersion]);

  useEffect(() => {
    // Set Menu is Open or Closed on first load
    if (menuInfo?.menuData) {
      handleIsOpen();
    }
    // Load menu data from the server
    else if (
      license &&
      license !== "pending" &&
      license !== "none" &&
      license !== "t" &&
      license !== "r"
    ) {
      dispatch(saveLicense(license));
      dispatch(getMenuLanguages(license));
    } else {
      // Get License from custom domain
      dispatch(customDomainLicense(window.location.hostname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license, menuInfo?.menuData]);

  useEffect(() => {
    if (["ar", "he"].includes(menuInfo.selected_language)) {
      document.body.style.textAlign = "right";
      document.body.dir = "rtl";
      document.dir = "rtl";
    } else {
      document.body.style.textAlign = "left";
      document.body.dir = "ltr";
      document.dir = "ltr";
    }
  }, [menuInfo.selected_language]);

  useEffect(() => {
    // This function makes sure we only load
    // required Google Fonts used in our theme
    let fonts = [];
    let uniqueFonts = [];

    let fontArray = [];

    const cleanFont = (font) => {
      let fn = font.split("-");
      fn.pop();
      fn = fn.join(" ");
      return `${fn}:400,700`;
    };

    const getFonts = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "object") {
          getFonts(obj[key]);
        }

        if (key.includes("_font_family")) {
          fontArray.push(cleanFont(obj[key]));
          //console.log(`key: ${key}, value: ${obj[key]}`);
        }
      });
      return fontArray;
    };

    if (menuInfo.menuData.theme_data) {
      fonts = getFonts(menuInfo.menuData.theme_data);
      fonts.push("Roboto:400,700");
      fonts.push("Roboto Slab:400,700");
      uniqueFonts = [...new Set(fonts)].filter(
        (e) =>
          [
            "ToxTypewriter:400,700",
            "Gill Sans:400,700",
            "Beatrice Display Medium:400,700",
            "Calibre Thin:400,700",
            "MSLondon:400,700",
          ].indexOf(e) === -1
      );

      // console.log(fonts);
      // console.log(uniqueFonts);

      // Set fonts
      uniqueFonts.length > 0 &&
        WebFont.load({
          google: {
            families: uniqueFonts,
          },
        });
    } else {
      WebFont.load({
        google: {
          families: ["Roboto:400,700", "Roboto Slab:400,700"],
        },
      });
    }
  }, [menuInfo.menuData.theme_data]);

  useEffect(() => {
    if (
      !afActiveOrder &&
      al_futtaim_enabled &&
      cOrderType === "dine_in" &&
      !!cOrderLocation
    ) {
      dispatch(getIikoActiveOrders());
    } else if (!["dine_in", "takeaway"].includes(cOrderType)) {
      // Reset active order
      dispatch(resetIikoActiveOrders());
    }

    if (!!afActiveOrder && cOrderType === "dine_in") {
      setActiveOrderNotification(true);
    } else {
      setActiveOrderNotification(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afActiveOrder, al_futtaim_enabled, cOrderType, cOrderLocation]);

  // Set Table or Room Number if special QR is used
  useEffect(() => {
    const qrType = pathname[customDomain ? 1 : 2]?.toLowerCase();
    if (qrType === "t") {
      setOrderLocationType(
        ui_strings?.ui_str_table ? ui_strings.ui_str_table : "Table"
      );
    } else if (qrType === "r") {
      setOrderLocationType(
        ui_strings?.ui_str_room ? ui_strings.ui_str_room : "Room"
      );
    }

    const orderLocation = hashids.decode(pathname[customDomain ? 2 : 3])[0];
    if (!!orderLocation) {
      setOrderLocation(orderLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (
      pathname[customDomain ? 1 : 2]?.toLowerCase() === "t" &&
      enable_table_orders_to_wishlist
    ) {
      dispatch(enableWishlist());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enable_table_orders_to_wishlist]);

  useEffect(() => {
    if (orderLocation && orderLocationType) {
      dispatch(saveOrderLocation(orderLocation, orderLocationType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderLocation, orderLocationType]);

  return (
    <RTL>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Digital eMenu</title>
        </Helmet>
        <Fragment>
          {menuInfo.menuData &&
          !menuInfo.loading &&
          license &&
          license !== "pending" ? (
            <>
              <Helmet>
                <link
                  rel="manifest"
                  href={APP_URL + "/en/menus/manifest/" + license + "/"}
                />
                <link
                  rel="apple-touch-startup-image"
                  href={`${APP_URL}/en/menus/${license}/loading-image/launch.png`}
                />
                <link
                  rel="apple-touch-icon"
                  href={`${APP_URL}/en/menus/manifest/${license}/icons/apple-touch-icon.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="152x152"
                  href={`${APP_URL}/en/menus/manifest/${license}/icons/apple-touch-icon-ipad.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="120x120"
                  href={`${APP_URL}/en/menus/manifest/${license}/icons/apple-touch-icon-iphone-retina.png`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="167x167"
                  href={`${APP_URL}/en/menus/manifest/${license}/icons/apple-touch-icon-ipad-retina.png`}
                />
              </Helmet>
              <Switch>
                <Route path="/home" component={HomePage} />
                <Route path="/search" component={SearchPage} />
                <Route path="/category/:id" component={CategoryPage} />
                <Route path="/product/:id" component={ProductPage} />
                <Route path="/page/:id" component={InformationPage} />
                <Route path="/form/:id" component={FormPage} />
                <Route path="/promotions" component={PromotionPage} />
                <Route path="/cart" component={CartPage} />
                <Route path="/delivery-location" component={DeliveryPage} />
                <Route path="/checkout" component={CheckoutPage} />
                <Route
                  path="/af-checkout/:id"
                  component={AlFuttaimCheckoutPage}
                />
                <Route path="/order/:id" component={OrderPage} />
                <Route path="/payment/paylink" component={PayLinkPage} />
                <Route path="/payment/ngenius" component={NgeniusPage} />
                <Route path="/payment/xpresspay" component={XpressPayPage} />
                <Route path="/payment/skipcash" component={SkipCashPage} />
                <Route path="/payment/paytabs" component={PayTabsPage} />
                <Route path="/payment/stripe" component={StripePage} />
                <Route path="/payment/noon" component={NoonPage} />
                <Route path="/" component={OnboardingPage} />
              </Switch>
            </>
          ) : (
            <Switch>
              {license === "1922" ? (
                // First Avenue
                <Route
                  exact
                  path="/"
                  render={() =>
                    (window.location = "https://demo.qr.emenu.ae/1922")
                  }
                />
              ) : license === "2841" ? (
                // Mamazonia
                <Route
                  exact
                  path="/"
                  render={() =>
                    (window.location = "https://qr.emenu.ae/dxpkg6onma/")
                  }
                />
              ) : license === "3008" ? (
                // Mercure - Cafe Social and The Exit Sports Bar
                <Route
                  exact
                  path="/"
                  render={() =>
                    (window.location = "https://mercure.qr.emenu.ae/")
                  }
                />
              ) : license === "none" ? (
                <Route path="/" component={WelcomePage} />
              ) : (
                <Route path="/" component={OnboardingPage} />
              )}
            </Switch>
          )}
        </Fragment>
        <Snackbar
          open={showReload}
          message={
            <div>
              <div>{`A new version (v.${serverVersion}) is available.`}</div>
              <div>{`Close all tabs and re-open the app or press "Update"`}</div>
            </div>
          }
          onClick={reloadPage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          action={
            <Button color="inherit" size="small" onClick={reloadPage}>
              Update
            </Button>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={activeOrderNotification}
          autoHideDuration={5000}
          onClose={handleActiveOrder}
          TransitionComponent={cartSnackbarTransition}
          key={`active-order`}
        >
          <Alert
            onClose={handleActiveOrder}
            severity="warning"
            icon={<ShoppingCartIcon fontSize="inherit" />}
          >
            Active order on this table
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openCartSuccess}
          autoHideDuration={5000}
          onClose={handleCartSuccess}
          TransitionComponent={cartSnackbarTransition}
          key={`added-to-cart`}
        >
          <Alert
            onClose={handleCartSuccess}
            severity="success"
            icon={<ShoppingCartIcon fontSize="inherit" />}
          >
            Item added to cart
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </RTL>
  );
}
