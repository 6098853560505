import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Emenu/Header/Header.js";
import { useSelector } from "react-redux";
import PromotionStyle from "./PromotionStyle";
import firebase from "../../constants/Firebase";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { css } from "@emotion/css";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(PromotionStyle);
// install Swiper components
SwiperCore.use([Navigation, Pagination]);

export default function PromotionPage(props) {
  const { location } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const { ui_strings, promotions, theme_data, license } = menuInfo.menuData;

  const enabledPromotions = location.promotionData
    ? location.promotionData
    : promotions.filter((promotion) => promotion.enable);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.category_screen_bg_color;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const categoryBgStyle = {
    backgroundImage: `url(${menuInfo?.menuData?.category_background?.resized})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme_data?.category_screen_bg_color,
    height: "100vh",
    width: "inherit",
    maxWidth: "inherit",
    position: "fixed",
    zIndex: "-1000",
  };

  const swiperAnalytics = (swiper) => {
    // Analytics
    if (license) {
      firebase.analytics().logEvent("promotion_page_view", {
        license: license,
        promotion_id: enabledPromotions[swiper.activeIndex].id,
      });
    }
  };

  const onSlideChange = (swiper) => {
    swiperAnalytics(swiper);
  };

  return (
    <div className={classes.mainContainer}>
      <Header
        brand={
          ui_strings?.ui_str_promotions
            ? ui_strings.ui_str_promotions
            : "Promotions"
        }
      />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${
                ui_strings?.ui_str_promotions
                  ? ui_strings.ui_str_promotions
                  : "Promotions"
              } | ${menuInfo.menuData?.name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div
        style={categoryBgStyle}
        className={classNames(classes.toolbar, classes.main)}
      />
      <div>
        <div className={classes.container}>
          <Swiper
            simulateTouch={false}
            slidesPerView={1}
            navigation={enabledPromotions.length > 1}
            pagination={{ clickable: true }}
            onInit={onSlideChange}
            onSlideChange={onSlideChange}
            className={css`
              > .swiper-button-prev,
              > .swiper-button-next {
                color: ${theme_data?.product_screen_arrow_color};
              }
            `}
          >
            {enabledPromotions.map((promo, index) => (
              <SwiperSlide key={index}>
                <img
                  src={promo.image.product}
                  className={classes.promoImage}
                  alt={promo.title}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
