import React, { useState } from "react";
import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { Images } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { currency_symbol, fontName } from "../../../constants/utils";
import QuantityButton from "../Quantity/QuantityButton";
import { removeCart } from "../../../store/actions/cart";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles(CartStyle);

export default function CartProduct(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    currency,
    display_currency,
    default_product_image,
    price_decimal_places,
    theme_data,
  } = menuInfo.menuData;
  const {
    uuid,
    image,
    name,
    price,
    qty,
    special_instructions,
    variations,
    combo_groups,
    max_qty,
  } = props.item;

  // Item Name Style
  const productTitleStyle = {
    fontSize: Number(theme_data?.cart_screen_cart_item_title_font_size),
    color: theme_data?.cart_screen_cart_item_title_text_color,
    ...fontName(theme_data?.cart_screen_cart_item_title_font_family),
  };

  // Item Price Style
  const priceTextStyle = {
    fontSize: Number(theme_data?.cart_screen_cart_item_price_font_size),
    color: theme_data?.cart_screen_cart_item_price_text_color,
    ...fontName(theme_data?.cart_screen_cart_item_price_font_family),
  };

  // Item Qty
  const [quantity, setQty] = useState(qty);

  return (
    <Card className={classes.card}>
      <div
        className={classes.itemDetailsContainer}
        style={{
          maxWidth: combo_groups?.length > 0 ? "90%" : "75%",
        }}
      >
        <img
          className={classes.itemImage}
          src={
            image?.thumb
              ? image.thumb
              : default_product_image?.resized
              ? default_product_image.resized
              : Images.PlaceHolderProduct.image
          }
          alt={name}
        />

        <div className={classes.itemDetails}>
          <div style={productTitleStyle}>{name}</div>

          {special_instructions && (
            <div className={classes.itemSpecialInstructions}>
              {special_instructions}
            </div>
          )}

          {variations.length > 0 && (
            <div>
              {variations.map((variation, index) => (
                <div key={index} className={classes.itemVariation}>
                  <div className={classes.itemVariationName}>
                    {variation.name}:
                  </div>
                  <ul className={classes.itemVariationList}>
                    {variation.values.map((v, i) =>
                      v.selected ? (
                        <li key={i} className={classes.itemVariationValue}>
                          {v.name}
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
              ))}
            </div>
          )}

          {combo_groups?.length > 0 && (
            <div
              className={classes.itemVariationName}
              style={{ marginBottom: 4 }}
            >
              {combo_groups.map((cg, cgi) => (
                <div key={cgi}>
                  <div>{cg.name}</div>
                  <ul className={classes.itemVariationList}>
                    {cg.products
                      .filter((cgp) => cgp.selected)
                      .map((cgp, i) => (
                        <li key={i} className={classes.itemVariationValue}>
                          {cgp.name}
                          {cgp.product_variations.map((variation, index) => (
                            <div key={index} className={classes.itemVariation}>
                              <div className={classes.itemVariationName}>
                                {variation.name}:
                              </div>
                              <ul className={classes.itemVariationList}>
                                {variation.values.map((v, i) =>
                                  v.selected ? (
                                    <li
                                      key={i}
                                      className={classes.itemVariationValue}
                                    >
                                      {v.name}
                                    </li>
                                  ) : null
                                )}
                              </ul>
                            </div>
                          ))}
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          <div style={priceTextStyle}>
            {display_currency ? currency_symbol(currency) : null}
            {(parseFloat(price) * quantity).toFixed(price_decimal_places)}
          </div>
        </div>
      </div>

      <div
        className={classes.itemQty}
        style={{
          maxWidth: combo_groups.length > 0 ? "10%" : "25%",
        }}
      >
        {!combo_groups.length > 0 && (
          <QuantityButton
            uuid={uuid}
            qty={quantity}
            setQty={setQty}
            maxQty={max_qty}
            cart={true}
          />
        )}
        <IconButton
          style={{
            color: theme_data?.cart_screen_cart_item_delete_btn_color,
          }}
          className={classes.itemDeleteBtn}
          color="inherit"
          aria-label="delete"
          onClick={() => dispatch(removeCart(uuid))}
        >
          <DeleteIcon fontSize={"small"} />
        </IconButton>
      </div>
    </Card>
  );
}
