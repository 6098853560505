import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Emenu/Header/Header";
import CategoryStyle from "../CategoryPage/CategoryStyle";
import { useDispatch, useSelector } from "react-redux";
import { flat, fontName, is_open } from "../../constants/utils";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import { Images } from "../../constants";
import ProductPricing from "../../components/Emenu/Pricing/ProductPricing";
import EmenuNavlink from "../../components/Emenu/Navlink/EmenuNavlink";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Search } from "@material-ui/icons";
import { css } from "@emotion/css";
import { saveSearch } from "../../store/actions/search";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import Badge from "components/Badge/Badge.js";
import chefRecommendsIcon from "../../assets/img/emenu/chef-recommends-icon.png";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(CategoryStyle);

export default function SearchPage(props) {
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const searchInfo = useSelector((state) => state.searchInfo);

  const { searchTerm, checkedProperties } = searchInfo;

  const {
    ui_strings,
    properties,
    timezone,
    theme_data,
    categories,
    taxes,
    display_prices_with_tax,
  } = menuInfo.menuData;
  const defaultProductImage = {
    image: menuInfo.menuData?.default_product_image?.resized,
    width: menuInfo.menuData?.default_product_image_width,
    height: menuInfo.menuData?.default_product_image_height,
  };
  const [searchText, setSearchText] = useState(searchTerm);
  const [searchableProducts] = useState(
    flat(
      categories
        .filter(
          (category) =>
            !category.disable_link &&
            !category.external_link &&
            !category.enable_special_category &&
            is_open(category.category_hours, [], timezone)
        )
        .map((category) => category.products)
    )
  );
  const [searchProducts, setSearchProducts] = useState([]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    dispatch(saveSearch(value, checked));
  };

  // Property checkbox
  const [checked, setChecked] = useState(checkedProperties);

  const handlePropertyToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    dispatch(saveSearch(searchText, newChecked));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.category_screen_bg_color;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchText || checked.length > 0) {
      let newSearchProducts = [...searchableProducts];
      if (searchText) {
        newSearchProducts = newSearchProducts.filter((product) =>
          product.name.toLowerCase().includes(searchText.toLowerCase())
        );
      }
      if (checked.length > 0) {
        newSearchProducts = newSearchProducts.filter((product) =>
          product.properties.some((p) => checked.indexOf(p) !== -1)
        );
      }
      setSearchProducts(newSearchProducts);
    } else {
      setSearchProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, checked]);

  const classes = useStyles();

  const categoryBgStyle = {
    backgroundImage: `url(${menuInfo?.menuData?.category_background?.resized})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme_data?.category_screen_bg_color,
    height: "100vh",
    width: "inherit",
    maxWidth: "inherit",
    position: "fixed",
    zIndex: "-1000",
  };

  const productPriceStyleZeroCondition =
    Number(theme_data?.category_screen_product_price_font_size) === 0
      ? { lineHeight: 0 }
      : null;

  const productPriceStyle = {
    fontSize: Number(theme_data?.category_screen_product_price_font_size),
    color: theme_data?.category_screen_product_price_text_color,
    ...fontName(theme_data?.category_screen_product_price_font_family),
    ...productPriceStyleZeroCondition,
  };

  const productNameStyle = {
    fontSize: Number(theme_data?.category_screen_product_title_font_size),
    color: theme_data?.category_screen_product_title_text_color,
    ...fontName(theme_data?.category_screen_product_title_font_family),
  };

  const propertyBadgeStyle = {
    backgroundColor: theme_data?.product_screen_property_bg_color,
    color: theme_data?.product_screen_property_text_color,
    fontSize: Number(theme_data?.product_screen_property_font_size),
    ...fontName(theme_data?.product_screen_property_font_family),
  };

  const renderProducts = () => {
    const getTaxes = (price) => {
      let taxAmount = 0;
      if (taxes.length > 0 && display_prices_with_tax) {
        for (let tax of taxes) {
          if (tax.type === "percentage") {
            taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
          }
        }
      }
      return taxAmount;
    };

    const getPrice = (product) => {
      return (
        parseFloat(product.price) + getTaxes(parseFloat(product.price))
      ).toFixed(3);
    };

    const getDiscountedPrice = (product) => {
      if (product.discount > 0) {
        const discountedPrice =
          parseFloat(product.price) -
          (parseFloat(product.price) * product.discount) / 100;
        return (
          parseFloat(discountedPrice) + getTaxes(parseFloat(discountedPrice))
        );
      } else {
        return 0;
      }
    };

    return (
      <div>
        <div className={classes.products}>
          {searchProducts
            .filter(
              (product) =>
                !product.disable_link &&
                !product.external_link &&
                is_open(product.product_hours, [], timezone)
            )
            .map((product) => (
              <Card
                key={product.id}
                className={classes.card}
                style={{
                  backgroundColor: !product.hidden_title
                    ? theme_data?.category_screen_product_bg_color
                    : "transparent",
                  width: product.fullwidth ? "95%" : "45%",
                }}
                plain={!product.card_shadow}
              >
                <EmenuNavlink
                  disabled={product.disable_link}
                  to={{
                    pathname: `/product/${product.id}`,
                    searchData: searchProducts,
                  }}
                >
                  <CardHeader
                    image
                    plain
                    noShadow={!product.card_shadow}
                    children={
                      !product.hidden_image ? (
                        <img
                          src={
                            product.images[0]
                              ? product.images[0].image.product
                              : defaultProductImage.image
                              ? defaultProductImage.image
                              : Images.PlaceHolderProduct.image
                          }
                          className={classes.categoryImage}
                          width={
                            product.images[0]
                              ? product.images[0].image_width
                              : defaultProductImage.width
                              ? defaultProductImage.width
                              : Images.PlaceHolderProduct.width
                          }
                          height={
                            product.images[0]
                              ? product.images[0].image_height
                              : defaultProductImage.height
                              ? defaultProductImage.height
                              : Images.PlaceHolderProduct.height
                          }
                          alt={product.name}
                        />
                      ) : null
                    }
                  />

                  {!product.hidden_title ? (
                    <CardBody
                      className={classes.productTitle}
                      children={
                        <div
                          style={{
                            padding: product.hidden_image ? "14px 0" : 0,
                          }}
                        >
                          {product.recommend ? (
                            <div className={classes.chefRecommends}>
                              <img
                                src={chefRecommendsIcon}
                                alt={"Chef Recommends"}
                              />
                            </div>
                          ) : null}
                          <div style={productNameStyle}>{product.name}</div>
                          <div style={productPriceStyle}>
                            {product.price > 0 ? (
                              <ProductPricing
                                product={product}
                                price={getPrice(product)}
                                discountedPrice={getDiscountedPrice(product)}
                              />
                            ) : null}
                          </div>
                        </div>
                      }
                    />
                  ) : null}
                </EmenuNavlink>
              </Card>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.mainContainer}>
      <Header
        goback={false}
        brand={ui_strings?.ui_str_search ? ui_strings.ui_str_search : "Search"}
      />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${
                ui_strings?.ui_str_search ? ui_strings.ui_str_search : "Search"
              } | ${menuInfo.menuData?.name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div
        style={categoryBgStyle}
        className={classNames(classes.toolbar, classes.main)}
      />
      <div>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText={
                  ui_strings?.ui_str_search
                    ? ui_strings.ui_str_search
                    : "Search"
                }
                id="search"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoFocus: true,
                  value: searchText || "",
                  onChange: handleSearchChange,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <div className={classes.dishProperties}>
                {properties.map((property) => (
                  <div
                    className={classNames(
                      classes.checkboxAndRadio,
                      classes.checkboxAndRadioHorizontal
                    )}
                    key={property.id}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checked={checked.indexOf(property.id) !== -1}
                          onClick={() => handlePropertyToggle(property.id)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label={
                        <Badge style={propertyBadgeStyle}>
                          {property.name}
                        </Badge>
                      }
                    />
                  </div>
                ))}
              </div>
            </GridItem>
          </GridContainer>
          {searchProducts.length > 0 ? renderProducts() : null}
        </div>
      </div>
    </div>
  );
}
