const ENV = {
  local: {
    APP_URL: "http://localhost:8000",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
      projectId: "emenu-543ab",
      appId: "1:540059913797:web:2dbce0456e876f14a29d2c",
      measurementId: "G-Z3ZCBGP2VC",
    },
  },
  dev: {
    APP_URL: "https://app.emenu.dev",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
      projectId: "emenu-543ab",
      appId: "1:540059913797:web:2dbce0456e876f14a29d2c",
      measurementId: "G-Z3ZCBGP2VC",
    },
  },
  staging: {
    APP_URL: "https://staging.app.emenu.ae",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
      projectId: "emenu-543ab",
      appId: "1:540059913797:web:2dbce0456e876f14a29d2c",
      measurementId: "G-Z3ZCBGP2VC",
    },
  },
  prod: {
    APP_URL: "https://app.emenu.ae",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyA5JAZwLYbFC23sNV8eQlybGH_nY864Q6A",
      projectId: "emenu-543ab",
      appId: "1:540059913797:web:b45414831389a4b4a29d2c",
      measurementId: "G-4ZVRC0HEZX",
    },
  },
};

function getEnvVars() {
  const env = process.env.REACT_APP_TARGET_ENV || "production"; // Default to "production" if not set
  const envVars = ENV[env];

  if (!envVars) {
    console.warn(
      `Unknown environment: ${env}. Defaulting to "production" environment.`
    );
    return ENV.prod;
  }

  return envVars;
}

const { APP_URL, FIREBASE_CONFIG } = getEnvVars();

export { APP_URL, FIREBASE_CONFIG };
