import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { flat, is_open } from "../../constants/utils";
import Product from "../../components/Emenu/Product/Product";
import Header from "../../components/Emenu/Header/Header";
import SwiperCore, { EffectCoverflow, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import { css } from "@emotion/css";
import firebase from "../../constants/Firebase";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import ProductStyle from "../../components/Emenu/Product/ProductStyle";

// install Swiper components
SwiperCore.use([Navigation, EffectCoverflow]);

const useStyles = makeStyles(ProductStyle);

export default function ProductPage(props) {
  const { match, location } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const { categories, timezone, theme_data, license } = menuInfo.menuData;
  const allProducts = flat(categories.map((category) => category.products));
  const currentProduct = allProducts.filter(
    (product) => product.id === Number(match.params.id)
  )[0];
  const currentCategoryProducts = useMemo(
    () =>
      location.searchData
        ? location.searchData
        : categories
            .filter((category) => category.id === currentProduct.category)[0]
            .products.filter(
              (product) =>
                !product.disable_link &&
                !product.external_link &&
                is_open(product.product_hours, [], timezone)
            ),
    [categories, currentProduct.category, location.searchData, timezone]
  );
  const currentProductIndex = currentCategoryProducts.findIndex(
    (product) => product.id === currentProduct.id
  );

  const classes = useStyles();

  const [headerTitle, setHeaderTitle] = useState(currentProduct.name);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.product_screen_bg_color;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const swiperAnalytics = (swiper) => {
    // Analytics
    if (license) {
      firebase.analytics().logEvent("product_page_view", {
        license: license,
        product_name: currentCategoryProducts[swiper.activeIndex].name,
        product_id: currentCategoryProducts[swiper.activeIndex].id,
      });
    }
  };

  const onFirstSlide = (swiper) => {
    if (swiper.activeIndex === 0) {
      swiperAnalytics(swiper);
    }
  };

  const [selectedProduct, setSelectedProduct] = useState(currentProduct.id);
  const onSlideChange = (swiper) => {
    setSelectedProduct(currentCategoryProducts[swiper.activeIndex].id);
    setHeaderTitle(currentCategoryProducts[swiper.activeIndex].name);
    swiperAnalytics(swiper);
  };

  return (
    <div className={classes.mainContainer}>
      <Header goback brand={headerTitle} />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${headerTitle} | ${menuInfo.menuData?.name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <Swiper
        slidesPerView={1}
        simulateTouch={false}
        initialSlide={currentProductIndex}
        autoHeight={false}
        navigation={currentCategoryProducts.length > 1}
        noSwipingClass={"swiper-no-swiping"}
        onSlideChange={onSlideChange}
        onInit={onFirstSlide}
        effect={"coverflow"}
        className={classNames(
          css`
            min-height: 100vh;

            > .swiper-button-prev,
            > .swiper-button-next {
              color: ${theme_data?.product_screen_arrow_color};
            }
          `,
          "product-swiper"
        )}
      >
        {currentCategoryProducts.map((product, index) => {
          return (
            <SwiperSlide key={index}>
              <Product
                currentProduct={product}
                displayVariations={product.id === selectedProduct}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
